import app from "@/main.js";
import gql from 'graphql-tag';
import { nodeDefaultData } from "@/store/main/state";
import { NodesQueriesBySiteID, NodesQueriesBySiteIDWithFilter, NodeQueriesByID, NodesQueriesByDevicegroupID, NodesQueriesBySiteIdWithoutDevicegroup, ScheduleQueries, TestQueriesByNodeId, 
        siteGatewaysQuery, siteTenantsQuery, siteIdQueryByNodeId, sitesInfoQuery, sitesInfoOnlyTestFailedQuery, siteUpcomingTests, siteDetailsQuery, 
        motionEventsQuery, batteryReplacementEventsQuery, powerEventsQuery, failedNodesQuery, user, users, tenant, tenants, 
        tenantsName, devicegroup, devicegroups, devicegroupsName, sitesName, entity, entities, entitySitesQuery, entityUsersQuery, 
        entityAllUsersQuery, sensorSettingsQuery, groupSensorSettingQuery, requestFirmwareSensorSettingsQuery
       } from "../../graphql/queries";
import CREATE_USER from '../../graphql/CreateUser.gql';
import UPDATE_USER from '../../graphql/UpdateUser.gql';
import UPDATE_LOGIN_USER from '../../graphql/UpdateLoginUser.gql';
import UPDATE_DEVICE from '../../graphql/UpdateDevice.gql';
import CREATE_SITE from '../../graphql/CreateSite.gql';
import UPDATE_SITE_DETAILS from '../../graphql/UpdateSiteDetails.gql';
import DELETE_SITE from '../../graphql/DeleteSite.gql';
import DELETE_USER from '../../graphql/DeleteUser.gql';
import RESET_MFA from '../../graphql/ResetMFA.gql';
import CREATE_TENANT from '../../graphql/CreateTenant.gql';
import UPDATE_TENANT from '../../graphql/UpdateTenant.gql';
import DELETE_TENANT from '../../graphql/DeleteTenant.gql';
import CREATE_DEVICEGROUP from '../../graphql/CreateDevicegroup.gql';
import UPDATE_DEVICEGROUP from '../../graphql/UpdateDevicegroup.gql';
import DELETE_DEVICEGROUP from '../../graphql/DeleteDevicegroup.gql';
import CREATE_ENTITY from '../../graphql/CreateEntity.gql';
import UPDATE_ENTITY from '../../graphql/UpdateEntity.gql';
import DELETE_ENTITY from '../../graphql/DeleteEntity.gql';
import UPDATE_GATEWAY from '../../graphql/UpdateGateway.gql';
import DELETE_DEVICE from '../../graphql/DeleteDevice.gql';

import CREATE_SCHEDULE from '../../graphql/CreateSchedule.gql';
import DELETE_SCHEDULE from '../../graphql/DeleteSchedule.gql';
import UPDATE_SCHEDULE from '../../graphql/UpdateSchedule.gql';
import DELETE_DEVICE_HISTORY from '../../graphql/DeleteDeviceHistory.gql';
import START_TEST from '../../graphql/StartTest.gql';
import {TEST_TYPE_MAP} from "../../constants";
import STOP_TEST from '../../graphql/StopTest.gql';
import IDENTIFY from '../../graphql/Identify.gql';
import RESET_TEST_STATUS from '../../graphql/ResetTestStatus.gql';
import UPDATE_FIRMWARE_SENSOR_SETTING_SET from '../../graphql/UpdateFirmwareSensorSetting.gql';
import RESET_FIRMWARE_SCHEDULE from '../../graphql/ResetFirmwareSchedule.gql'
import SET_FIRMWARE_SCHEDULE from '../../graphql/SetFirmwareSchedule.gql'
import SPI_MEMORY_DELETE_ALL from '../../graphql/SPIMemoryDeleteAll.gql'
import BLE_VERSION from '../../graphql/bleversion.gql'
import SITE_TEST from '../../graphql/SiteTest.gql'
import SITE_DIAGNOSTICS from '../../graphql/SiteDiagnostics.gql'
import CREATE_USERENTITY from '../../graphql/CreateUserentity.gql';
import DELETE_USERENTITY from '../../graphql/DeleteUserentity.gql';
import UPDATE_GROUP_SENSOR_SETTING from '../../graphql/UpdateGroupSensorSetting.gql';

const axios = require('axios').default;

export const actionTypes = {
    SET_USER: '[USERS] SET_USER',
    SET_USERS: '[USERS] SET_USERS',
    SET_SITE: '[SITE] SET_SITE',
    SET_SITES: '[SITES] SET_SITES',
    SET_TENANT: '[TENANT] SET_TENANT',
    SET_TENANTS: '[TENANTS] SET_TENANTS',
    SET_TENANTSNAME: '[TENANTSNAME] SET_TENANTSNAME',
    SET_DEVICEGROUP: '[DEVICEGROUP] SET_DEVICEGROUP',
    SET_DEVICEGROUPS: '[DEVICEGROUPS] SET_DEVICEGROUPS',
    SET_DEVICEGROUPSNAME: '[DEVICEGROUPSNAME] SET_DEVICEGROUPSNAME',
    SET_SITESNAME: '[SITESNAME] SET_SITESNAME',
    SET_ENTITY: '[ENTITY] SET_ENTITY',
    SET_ENTITIES: '[ENTITIES] SET_ENTITIES',
    NODE_TESTS: '[NODE] SET_TESTS',
    SITE_NODES: '[SITES] SET_SITE_NODES',
    SITE_PRODUCTLIST: '[SITES] SET_SITE_PRODUCTLIST',
    SITE_LOCATIONLIST: '[SITES] SET_SITE_LOCATIONLIST',
    SET_HAS_TEST_REQUEST: '[NODE] SET_TEST_REQUEST',
    SET_NODE_LAST_TEST_INFO: '[NODE] SET_LAST_TEST',
    SET_NODES_DATA: '[NODE] SET_NODES_DATA',
    SET_HAS_CANCEL_REQUEST: '[NODE] SET_CANCEL_REQUEST',
    GET_SCHEDULES: '[SCHEDULES] GET_SCHEDULES',
    SET_MOTION_EVENTS: '[NODE] SET_MOTION_EVENTS',
    SET_BATTERY_REPLACEMENT_EVENTS: '[NODE] SET_BATTERY_REPLACEMENT_EVENTS',
    SET_POWER_EVENTS: '[NODE] SET_POWER_EVENTS',
    SET_SITE_STATS: '[SITE] SET_SITE_STATS',
    SET_SERVER_BLE_VERSION: '[SITES] SET_SERVER_BLE_VERSION',
    SET_CURRENT_TIMEZONE: '[SITE] SET_CURRENT_TIMEZONE',
    SET_UPCOMING_TESTS: '[SITE] SET_UPCOMING_TESTS',
    SET_DEVICE_LOGS_STATE: '[NODE] NODE_LOGS',
    SET_SITES_WITH_DETAILS: '[SITES] SET_SITES_DETAILS',
    SET_SITENODESLIST_USERCONTROL: '[SITE] SET_SITENODESLIST_USERCONTROL'
}
/* eslint-disable */
export const actions = {
    setTimezone: ({ commit }, payload) => {
        commit(actionTypes.SET_CURRENT_TIMEZONE, payload)
    },

    getUser: async ({commit}, payload) => {
        localStorage.removeItem('user');
        const { data } = await app.$apollo.query({
            query: gql`${user}`,
            fetchPolicy: 'network-only',
        }).catch(err => {
            app.$notify({
                group: "error",
                type: "error",
                title: `Error!`,
                text: `${err.message}`
            });
            return;
        });
        commit(actionTypes.SET_USER, data.me);
    },
    getUsers: async ({commit}, payload) => {
        localStorage.removeItem('users');
        const { take, skip, orderBy } = payload;
        const res = await app.$apollo.query({
            query: gql`${users}`,
            fetchPolicy: 'network-only',
            variables: {
                take,
                skip,
                orderBy,
            }
        }).catch(err => {
            app.$notify({
                group: "error",
                type: "error",
                title: `Error!`,
                text: `${err.message}`
            });
            return;
        });
        
        if (res?.data) {  
          commit(actionTypes.SET_USERS, res.data.users);
        }
    },
    createUser: async ({ commit }, payload) => {
        await app.$apollo.mutate({
            mutation: CREATE_USER,
            variables: {
                data: payload.data,
            },
            update: (store, { data }) => {
                if (data.createUser.id) {
                    app.$notify({
                        group: "success",
                        type: "success",
                        title: "Success!",
                        text: "User details were updated successfully!"
                    });
                    app.$router.push(`/usermanage/${data.createUser.id}`);
                }
            }
        }).catch((e) => {
            app.$notify({
                group: "error",
                type: "error",
                title: "Error!",
                text: e.message,
            });
        });
    },
    updateUserDetails: async ({ commit }, payload) => {
        await app.$apollo.mutate({
            mutation: UPDATE_USER,
            variables: {
                id: payload.id,
                data: payload.data,
            },
            update: (store, { data }) => {
                if (data.updateUser.id && !payload.hasOwnProperty('noNotify')) {
                    app.$notify({
                        group: "success",
                        type: "success",
                        title: "Success!",
                        text: "User details were updated successfully!"
                    });
                }
            }
        }).catch((e) => {
            app.$notify({
                group: "error",
                type: "error",
                title: "Error!",
                text: e.message,
            });
        });
    },

    updateLoginUserDetails: async ({ commit }, payload) => {
        await app.$apollo.mutate({
            mutation: UPDATE_LOGIN_USER,
            variables: {
                data: payload.data,
            },
            update: (store, { data }) => {
                if (data.updateLoginUser.authChangedAt) {
                    app.$notify({
                        group: "success",
                        type: "success",
                        title: "Success!",
                        text: "User details were updated successfully!"
                    });
                }
            }
        }).catch((e) => {
            app.$notify({
                group: "error",
                type: "error",
                title: "Error!",
                text: e.message,
            });
        });
    },

    deleteUser: async ({ commit }, payload) => {
        let { userId } = payload;
        await app.$apollo.mutate({
            mutation: DELETE_USER,
            variables: {
                "userId": userId
            }
        }).then(data => {
         app.$notify({
                group: "success",
                type: "success",
                title: "Success",
                text: "User deleted"
            });
        }).catch(err => {
            app.$notify({
                group: "error",
                type: "error",
                title: `Error!`,
                text: `${err.message}`
            });
            return [];
        });
    },
    
    resetMFA: async ({ commit }, payload) => {
        let { userId } = payload;
        await app.$apollo.mutate({
            mutation: RESET_MFA,
            variables: {
                "userId": userId
            }
        }).then(data => {
         app.$notify({
                group: "success",
                type: "success",
                title: "Success",
                text: "MFA reset done"
            });
        }).catch(err => {
            app.$notify({
                group: "error",
                type: "error",
                title: `Error!`,
                text: `${err.message}`
            });
            return;
        });
    },

    getSites: async ({ commit }, payload) => {
        localStorage.removeItem('sites');
        const { take, skip, orderBy, tenantFilters } = payload;
        const res = await app.$apollo.query({
            query: gql`${sitesInfoQuery}`,
            fetchPolicy: 'network-only',
            variables: {
                take,
                skip,
                orderBy,
                tenantFilters,
            }
        }).catch(err => {
            app.$notify({
                group: "error",
                type: "error",
                title: `Error!`,
                text: `${err.message}`
            });
            return;
        });

        if (res?.data) {
            commit(actionTypes.SET_SITES, res.data.sitesInfo);
        }
    },
    getSitesOnlyTestFailed: async ({ commit }, payload) => {
        localStorage.removeItem('sites');
        const { take, skip, orderBy, tenantFilters } = payload;
        const res = await app.$apollo.query({
            query: gql`${sitesInfoOnlyTestFailedQuery}`,
            fetchPolicy: 'network-only',
            variables: {
                take,
                skip,
                orderBy,
                tenantFilters,
            }
        }).catch(err => {
            app.$notify({
                group: "error",
                type: "error",
                title: `Error!`,
                text: `${err.message}`
            });
            return;
        });

        if (res?.data) {
            commit(actionTypes.SET_SITES, res.data.sitesInfoOnlyTestFailed);
        }
    },
    getSiteGateways: async ({ commit }, payload) => {
        const {siteId} = payload || {siteId: null};

        // Get attached gateways
        let attached = await app.$apollo.query({
            query: gql`${siteGatewaysQuery}`,
            fetchPolicy: 'network-only',
            variables: {
                siteId
            }
        }).then(response => {
            return JSON.parse(JSON.stringify(response.data?.gateways));
        }).catch(err => {
            app.$notify({
                group: "error",
                type: "error",
                title: `Error!`,
                text: `${err.message}`
            });
        });

        // Get detached gateways
        let detached = {count: 0, rows: []};
        if (siteId != null) {
            detached = await app.$apollo.query({
                query: gql`${siteGatewaysQuery}`,
                fetchPolicy: 'network-only',
                variables: {
                    siteId: null
                }
            }).then(response => {
                return JSON.parse(JSON.stringify(response.data?.gateways));
            }).catch(err => {
                app.$notify({
                    group: "error",
                    type: "error",
                    title: `Error!`,
                    text: `${err.message}`
                });

            });
        }

        // Return site gateways
        return {attached, detached}
    },
    getSiteTenants: async ({ commit }, payload) => {
        const {siteId} = payload || {siteId: null};

        // Get attached tenants
        let attached = await app.$apollo.query({
            query: gql`${siteTenantsQuery}`,
            fetchPolicy: 'network-only',
            variables: {
                siteId
            }
        }).then(response => {
            return JSON.parse(JSON.stringify(response.data?.tenants));
        }).catch(err => {
            app.$notify({
                group: "error",
                type: "error",
                title: `Error!`,
                text: `${err.message}`
            });
        });

        // Get detached gateways
        let detached = {count: 0, rows: []};
        if (siteId != null) {
            detached = await app.$apollo.query({
                query: gql`${siteTenantsQuery}`,
                fetchPolicy: 'network-only',
                variables: {
                    siteId: null
                }
            }).then(response => {
                return JSON.parse(JSON.stringify(response.data?.tenants));
            }).catch(err => {
                app.$notify({
                    group: "error",
                    type: "error",
                    title: `Error!`,
                    text: `${err.message}`
                });

            });
        }

        // Return site tenants
        return {attached, detached}
    },
    getSiteIdByNodeId: async ({}, payload) => {
        const {nodeId} = payload;

        const res = await app.$apollo.query({
            query: gql`${siteIdQueryByNodeId}`,
            fetchPolicy: 'network-only',
            variables: {
                nodeId
            }
        }).then(response => {
            return JSON.parse(JSON.stringify(response.data?.sites));
        }).catch(err => {
            app.$notify({
                group: "error",
                type: "error",
                title: `Error!`,
                text: `${err.message}`
            });
            return;
        });
        return res;
    },
    setHasTestRequest: ({ commit }, payload) => {
        commit(actionTypes.SET_HAS_TEST_REQUEST, payload);
    },
    getNodeTests: async ({ commit }, payload) => {
        const { nodeId, take, skip } = payload;
        const res = await app.$apollo.query({
            query: gql`${TestQueriesByNodeId}`,
            fetchPolicy: 'network-only',
            variables: {
                nodeId,
                take,
                skip
            },
        }).catch(err => {
            app.$notify({
                group: "error",
                type: "error",
                title: `Error!`,
                text: `${err.message}`
            });
            return;
        })
        if (res?.data) {
            commit(actionTypes.NODE_TESTS, res.data.tests);
            if (res.data.tests?.rows?.length > 0 && skip === 0) {
                commit(actionTypes.SET_NODE_LAST_TEST_INFO, [nodeId, res.data.tests.rows[0]]);
            }
        } else {
            commit(actionTypes.NODE_TESTS, null);
        }
    },

    getSiteFaults: async ({ commit, state }, payload) => {
        try {
            const  { siteId, take, skip} = payload;
            const res = await app.$apollo.query({
                query: gql`${failedNodesQuery}`,
                variables: {
                    siteId,
                    take,
                    skip
                },
                fetchPolicy: 'network-only'
            }).catch(err => {
                app.$notify({
                    group: "error",
                    type: "error",
                    title: `Error!`,
                    text: `${err.message}`
                });
                return;
            });
            commit(actionTypes.SET_SITE_STATS, res?.data.failedNodes || []);
            
            let results = [];
            let nodes = res?.data?.failedNodes?.rows || [];
            for (let i = 0; i < nodes.length; i++) {

                let node = nodes[i];
                let date = "";
                let last_test = 0xFF;
                let outcome = 0xFF;

                if (node.lastTest) {
                    outcome = node.lastTest.status;
                    last_test = node.lastTest.type;
                    date = node.lastTest.startTime ? node.lastTest.startTime : null;
                }

                results.push({
                    label: node.name ? node.name : node.address,
                    id: node.id,
                    name: node.name,
                    location: node.location,
                    rssi: node.rssi,
                    address: node.address,
                    last_test: last_test,
                    date: date,
                    outcome: outcome,
                    appVersion: node.appVersion,
                    wpStackVersion: node.wpStackVersion,
                    bleAppCommsVersion: node.bleAppCommsVersion,
                    product: node.product ? node.product : { name: '', isEmgLight: false },
                    assetNo: node.assetNo,
                    sbcbNo: node.sbcbNo,
                    scheduledTest: node.scheduledTest,
                    scheduleFreq: node.scheduleFreq,
                    scheduleHour: node.scheduleHour,
                    scheduleMin: node.scheduleMin,
                    installDate: node.installDate,
                    lastCompletedTest: node.lastCompletedTest,
                    lastUpdated: node.lastUpdated,
                    lastSeen: node.lastSeen,
                    deviceGroupId: node.deviceGroup ? node.deviceGroup.id : null,
                    deviceGroupName: node.deviceGroup ? node.deviceGroup.name : "",
                });

                //Vue's reactivity system requires a WHOLE new copy of the object... lol.
                let newNodeData = {
                    ...state.nodeData
                };

                // Used to initialize a node's "frontend state data" if it did not exist previously
                if (!(node.id in newNodeData)) newNodeData[node.id] = {
                    ...nodeDefaultData
                }

                commit(actionTypes.SET_NODES_DATA, newNodeData);

            }

            results.sort((A, B) => {
                if (new Date(A.date).getTime() > new Date(B.date).getTime()) {
                    return -1;
                }
            });
            commit(actionTypes.SITE_NODES, {
                rows: results,
                count: res?.data?.failedNodes.count,
                originalCount: res?.data?.failedNodes.originalCount,
            });
            
        } catch (e) {
            app.$notify({
                group: "error",
                type: "error",
                title: `Error!`,
                text: `${e.message}`
            });
            return;
        }
    },

    getSiteNodes: async ({ commit, state }, payload) => {
        try {
            
            const { siteId, take, skip} = payload;
            const res = await app.$apollo.query({
                query: gql`${NodesQueriesBySiteID}`,
                variables: {
                    siteId,
                    take,
                    skip
                    //where,
                    //orderBy
                },
                fetchPolicy: 'network-only'
            }).catch(err => {
                app.$notify({
                    group: "error",
                    type: "error",
                    title: `Error!`,
                    text: `${err.message}`
                });
            });
            let results = [];
            let nodes = res?.data?.nodes?.rows || [];
            for (let i = 0; i < nodes.length; i++) {

                let node = nodes[i];
                let date = "";
                let last_test = 0xFF;
                let outcome = 0xFF;

                if (node.lastTest) {
                    outcome = node.lastTest.status;
                    last_test = node.lastTest.type;
                    date = node.lastTest.startTime ? node.lastTest.startTime : null;
                }

                results.push({
                    label: node.name ? node.name : node.address,
                    id: node.id,
                    name: node.name,
                    location: node.location,
                    rssi: node.rssi,
                    address: node.address,
                    last_test: last_test,
                    date: date,
                    outcome: outcome,
                    appVersion: node.appVersion,
                    wpStackVersion: node.wpStackVersion,
                    bleAppCommsVersion: node.bleAppCommsVersion,
                    product: node.product ? node.product : { name: '', isEmgLight: false },
                    assetNo: node.assetNo,
                    sbcbNo: node.sbcbNo,
                    scheduledTest: node.scheduledTest,
                    scheduleFreq: node.scheduleFreq,
                    scheduleHour: node.scheduleHour,
                    scheduleMin: node.scheduleMin,
                    installDate: node.installDate,
                    lastCompletedTest: node.lastCompletedTest,
                    lastUpdated: node.lastUpdated,
                    lastSeen: node.lastSeen,
                    deviceGroupId: node.deviceGroup ? node.deviceGroup.id : null,
                    deviceGroupName: node.deviceGroup ? node.deviceGroup.name : "",
                    serialNumber: node.serialNumber,
                });

                //Vue's reactivity system requires a WHOLE new copy of the object... lol.
                let newNodeData = {
                    ...state.nodeData
                };

                // Used to initialize a node's "frontend state data" if it did not exist previously
                if (!(node.id in newNodeData)) newNodeData[node.id] = {
                    ...nodeDefaultData
                }

                commit(actionTypes.SET_NODES_DATA, newNodeData);

            }

            results.sort((A, B) => {
                if (new Date(A.date).getTime() > new Date(B.date).getTime()) {
                    return -1;
                }
            });

            commit(actionTypes.SITE_NODES, {
                rows: results,
                count: res?.data?.nodes.count,
                originalCount: res?.data?.nodes.originalCount,
            });

        } catch (e) {
            app.$notify({
                group: "error",
                type: "error",
                title: `Error!`,
                text: `${e.message}`
            });
            return;
        }

    },
    getSiteNodesWithFilter: async ({ commit, state }, payload) => {
        try {
            
            const { siteId, take, skip, orderBy, deviceFilters, statusFilters, testTypeFilters, locationFilters, wirepasAddress, assetNo, deviceName, serialNumber} = payload;
            const res = await app.$apollo.query({
                query: gql`${NodesQueriesBySiteIDWithFilter}`,
                variables: {
                    siteId,
                    take,
                    skip,
                    orderBy,
                    deviceFilters,
                    statusFilters,
                    testTypeFilters,
                    locationFilters,
                    wirepasAddress,
                    assetNo,
                    deviceName,
                    serialNumber,
                    //where,
                },
                fetchPolicy: 'network-only'
            }).catch(err => {
                app.$notify({
                    group: "error",
                    type: "error",
                    title: `Error!`,
                    text: `${err.message}`
                });
            });
            let results = [];
            let nodes = res?.data?.nodesWithFilter?.rows || [];
            for (let i = 0; i < nodes.length; i++) {

                let node = nodes[i];
                let date = "";
                let last_test = 0xFF;
                let outcome = 0xFF;

                if (node.lastTest) {
                    outcome = node.lastTest.status;
                    last_test = node.lastTest.type;
                    date = node.lastTest.startTime ? node.lastTest.startTime : null;
                }

                results.push({
                    label: node.name ? node.name : node.address,
                    id: node.id,
                    name: node.name,
                    location: node.location,
                    rssi: node.rssi,
                    address: node.address,
                    last_test: last_test,
                    date: date,
                    outcome: outcome,
                    appVersion: node.appVersion,
                    wpStackVersion: node.wpStackVersion,
                    bleAppCommsVersion: node.bleAppCommsVersion,
                    product: node.product ? node.product : { name: '', isEmgLight: false },
                    assetNo: node.assetNo,
                    sbcbNo: node.sbcbNo,
                    scheduledTest: node.scheduledTest,
                    scheduleFreq: node.scheduleFreq,
                    scheduleHour: node.scheduleHour,
                    scheduleMin: node.scheduleMin,
                    installDate: node.installDate,
                    lastCompletedTest: node.lastCompletedTest,
                    lastUpdated: node.lastUpdated,
                    lastSeen: node.lastSeen,
                    deviceGroupId: node.deviceGroup ? node.deviceGroup.id : null,
                    deviceGroupName: node.deviceGroup ? node.deviceGroup.name : "",
                    serialNumber: node.serialNumber,
                });

                //Vue's reactivity system requires a WHOLE new copy of the object... lol.
                let newNodeData = {
                    ...state.nodeData
                };

                // Used to initialize a node's "frontend state data" if it did not exist previously
                if (!(node.id in newNodeData)) newNodeData[node.id] = {
                    ...nodeDefaultData
                }

                commit(actionTypes.SET_NODES_DATA, newNodeData);
            }

            commit(actionTypes.SITE_NODES, {
                rows: results,
                count: res?.data?.nodesWithFilter.count,
                originalCount: res?.data?.nodesWithFilter.originalCount,
            });

            commit(actionTypes.SITE_PRODUCTLIST,
                res?.data?.nodesWithFilter?.productList
            );
            
            commit(actionTypes.SITE_LOCATIONLIST,
                res?.data?.nodesWithFilter?.locationList
            );

        } catch (e) {
            app.$notify({
                group: "error",
                type: "error",
                title: `Error!`,
                text: `${e.message}`
            });
            return;
        }

    },
    getNode: async ({ commit, state }, payload) => {
        try {

            const { id } = payload;
            const res = await app.$apollo.query({
                query: gql`${NodeQueriesByID}`,
                variables: {
                    id,
                },
                fetchPolicy: 'network-only'
            }).catch(err => {
                app.$notify({
                    group: "error",
                    type: "error",
                    title: `Error!`,
                    text: `${err.message}`
                });
            });

            console.log(res?.data?.node);

            if (res?.data?.node) {
                let node = res?.data?.node;
                let date = "";
                let last_test = 0xFF;
                let outcome = 0xFF;

                if (node.lastTest) {
                    outcome = node.lastTest.status;
                    last_test = node.lastTest.type;
                    date = node.lastTest.startTime ? node.lastTest.startTime : null;
                }

                let results;
                if (state.siteNodes.rows)
                    results = state.siteNodes.rows.filter(item => item.id !== node.id);
                else
                    results = [];

                results.push({
                    label: node.name ? node.name : node.address,
                    id: node.id,
                    name: node.name,
                    location: node.location,
                    rssi: node.rssi,
                    address: node.address,
                    last_test: last_test,
                    date: date,
                    outcome: outcome,
                    appVersion: node.appVersion,
                    wpStackVersion: node.wpStackVersion,
                    bleAppCommsVersion: node.bleAppCommsVersion,
                    product: node.product ? node.product : { name: '', isEmgLight: false },
                    assetNo: node.assetNo,
                    sbcbNo: node.sbcbNo,
                    scheduledTest: node.scheduledTest,
                    scheduleFreq: node.scheduleFreq,
                    scheduleHour: node.scheduleHour,
                    scheduleMin: node.scheduleMin,
                    installDate: node.installDate,
                    lastCompletedTest: node.lastCompletedTest,
                    lastUpdated: node.lastUpdated,
                    lastSeen: node.lastSeen,
                    deviceGroupId: node.deviceGroup ? node.deviceGroup.id : null,
                    deviceGroupName: node.deviceGroup ? node.deviceGroup.name : "",
                    serialNumber: node.serialNumber ? node.serialNumber : null,
                });

                //Vue's reactivity system requires a WHOLE new copy of the object... lol.
                let newNodeData = {
                    ...state.nodeData
                };

                // Used to initialize a node's "frontend state data" if it did not exist previously
                if (!(node.id in newNodeData)) newNodeData[node.id] = {
                    ...nodeDefaultData
                }

                commit(actionTypes.SET_NODES_DATA, newNodeData);

                commit(actionTypes.SITE_NODES, {
                    rows: results,
                    count: state.siteNodes.count,
                    originalCount: state.siteNodes.originalCount,
                });
            }
        } catch (e) {
            app.$notify({
                group: "error",
                type: "error",
                title: `Error!`,
                text: `${e.message}`
            });
            return;
        }

    },
    getDevicegroupNodes: async ({ commit, state }, payload) => {
        try {
            const { deviceGroupId, take, skip} = payload;
            const res = await app.$apollo.query({
                query: gql`${NodesQueriesByDevicegroupID}`,
                variables: {
                    deviceGroupId,
                    take,
                    skip
                    //where,
                    //orderBy
                },
                fetchPolicy: 'network-only'
            }).catch(err => {
                app.$notify({
                    group: "error",
                    type: "error",
                    title: `Error!`,
                    text: `${err.message}`
                });
            });
            const nodes = res?.data?.nodes;
            return nodes;

        } catch (e) {
            app.$notify({
                group: "error",
                type: "error",
                title: `Error!`,
                text: `${e.message}`
            });
            return;
        }
    },
    getDevicegroupNodesAddable: async ({ commit, state }, payload) => {
        try {
            
            const { siteId, take, skip, deviceFilters, locationFilters} = payload;
            const res = await app.$apollo.query({
                query: gql`${NodesQueriesBySiteIdWithoutDevicegroup}`,
                variables: {
                    siteId,
                    take,
                    skip,
                    //where,
                    //orderBy,
                    deviceFilters,
                    locationFilters
                },
                fetchPolicy: 'network-only'
            }).catch(err => {
                app.$notify({
                    group: "error",
                    type: "error",
                    title: `Error!`,
                    text: `${err.message}`
                });
            });
            
            const nodes = res?.data?.nodesWithFilter;
            
            commit(actionTypes.SITE_LOCATIONLIST,
                res?.data?.nodesWithFilter?.locationList
            );
            
            return nodes;

        } catch (e) {
            app.$notify({
                group: "error",
                type: "error",
                title: `Error!`,
                text: `${e.message}`
            });
            return;
        }
    },

    startTest: async ({ commit }, payload) => {
        let { nodeId, testType } = payload;
        commit(actionTypes.SET_HAS_TEST_REQUEST, [nodeId, true]);
        let err = await app.$apollo.mutate({
            mutation: gql`${START_TEST}`,
            variables: {
                nodeId: nodeId,
                testType: TEST_TYPE_MAP[testType].toLowerCase(),
            }
        }).then((data) => {
            if (data.data.startTest?.error) {
                let timed_out = false;
                let status = data.data.startTest?.status
                if (status === 504 || status === 408) {
                    timed_out = true;
                }
                if (timed_out) {
                    app.$notify({
                        group: "warning",
                        title: 'Device Unavailable',
                        text: 'Device did not respond! Check your connection...'
                    })
                } else {
                    app.$notify({
                        group: "error",
                        type: "error",
                        title: `Error!`,
                        text: `${data.data.startTest?.error}`
                    });
                }

            } else {
                commit(actionTypes.SET_NODE_LAST_TEST_INFO, [nodeId, { status: -1, type: testType }])
            }

            commit(actionTypes.SET_HAS_TEST_REQUEST, [nodeId, false]);
            return data.data.startTest?.error;
        }).catch(err => {
            app.$notify({
                group: "error",
                type: "error",
                title: `Error!`,
                text: `${err.message}`
            });
        });

        return !err;
    },
    stopTest: async ({ commit }, payload) => {

        let { nodeId } = payload;
        commit(actionTypes.SET_HAS_CANCEL_REQUEST, [nodeId, true]);
        let err = await app.$apollo.mutate({
            mutation: gql`${STOP_TEST}`,
            variables: {
                nodeId: nodeId,
            }
        }).then((data) => {
            if (data.data.startTest?.error) {
                let timed_out = false;
                let status = data.data.stopTest?.status
                if (status === 504 || status === 408) {
                    timed_out = true;
                }
                if (timed_out) {
                    app.$notify({
                        group: "warning",
                        title: 'Device Unavailable',
                        text: 'Device did not respond! Check your connection...'
                    })
                } else {
                    app.$notify({
                        group: "error",
                        type: "error",
                        title: `Error!`,
                        text: `${data.data.startTest?.error}`
                    });
                }

            }

            commit(actionTypes.SET_HAS_CANCEL_REQUEST, [nodeId, false]);
            return data.data.startTest?.error;
        }).catch(err => {
            app.$notify({
                group: "error",
                type: "error",
                title: `Error!`,
                text: `${err.message}`
            });
        });

        return !err;
    },

    updateDeviceDetails: async ({ commit }, payload) => {
        await app.$apollo.mutate({
            mutation: UPDATE_DEVICE,
            variables: {
                id: payload.id,
                data: payload.data,
            },
            update: (store, { data }) => {
                if (data.updateNode.id) {
                    app.$notify({
                        group: "success",
                        type: "success",
                        title: "Success!",
                        text: "Device details was updated successfully!"
                    });
                }
            }
        }).catch((e) => {
            app.$notify({
                group: "error",
                type: "error",
                title: "Error!",
                text: `Device ${payload.data.name ? payload.data.name : payload.data.address} is not responding`,
            });
            throw error;
        })
    },
    deleteDevice: async ({ state }, payload) => {
        let { device_id } = payload;
        await app.$apollo.mutate({
            mutation: DELETE_DEVICE,
            variables: {
                "nodeId": device_id
            },
            update: (store, { data }) => {
                app.$notify({
                    group: "success",
                    type: "success",
                    title: "Success!",
                    text: `The device was removed successfully!`
                });
                return true;
            }
        }).catch(err => {
            app.$notify({
                group: "error",
                type: "error",
                title: `Error!`,
                text: `${err.message}`
            });
            return [];
        });
    },

    createSchedule: async ({ commit, dispatch }, payload) => {
        await app.$apollo.mutate({
            mutation: CREATE_SCHEDULE,
            variables: {
                data: payload
            },
            update: (store, { data }) => {
                app.$notify({
                    group: "success",
                    type: "success",
                    title: "Success!",
                    text: `Schedule [${payload.name}] is created successfully!`
                });
                dispatch('getSchedulesByNode', {
                    device_id: payload?.nodeId
                })
                return true;
            }
        }).catch((e) => {
            app.$notify({
                group: "error",
                type: "error",
                title: "Error!",
                text: e.message,
            });
            return false;
        })
    },
    updateSchedule: async ({ commit, dispatch }, payload) => {
        await app.$apollo.mutate({
            mutation: UPDATE_SCHEDULE,
            variables: {
                id: payload.id,
                data: payload.data
            },
            update: (store, { data }) => {
                app.$notify({
                    group: "success",
                    type: "success",
                    title: "Success!",
                    text: `The selected schedule ${name} was removed successfully!`
                });
                dispatch('getSchedulesByNode', {
                    device_id: payload.device_id
                })
                return true;
            }
        }).catch((e) => {
            app.$notify({
                group: "error",
                type: "error",
                title: "Error!",
                text: e.message,
            });
            return false;
        });
    },
    deleteSchedule: async ({ commit, dispatch }, payload) => {
        const { event_id, name, device_id } = payload;
        await app.$apollo.mutate({
            mutation: DELETE_SCHEDULE,
            variables: {
                id: event_id
            },
            update: (store, { data }) => {
                app.$notify({
                    group: "success",
                    type: "success",
                    title: "Success!",
                    text: `The selected schedule ${name} was removed successfully!`
                });
                dispatch('getSchedulesByNode', {
                    device_id: device_id
                })
                return true;
            }
        }).catch((e) => {
            app.$notify({
                group: "error",
                type: "error",
                title: "Error!",
                text: e.message,
            });
            return false;
        });
        return true;
    },

    getSchedulesByNode: async ({ commit }, payload) => {
        const { device_id } = payload;
        const res = await app.$apollo.query({
            query: gql`${ScheduleQueries}`,
            fetchPolicy: 'network-only',
            variables: {
                nodeId: device_id
            },
        }).catch(err => {
            app.$notify({
                group: "error",
                type: "error",
                title: `Error!`,
                text: `${err.message}`
            });
        });

        if (res?.data) {
            commit(actionTypes.GET_SCHEDULES, res.data.schedules.rows);
        }
    },

    getMotionEventsByNode: async ({ commit }, payload) => {
        const { nodeId, take, skip } = payload
        const res = await app.$apollo.query({
            query: gql`${motionEventsQuery}`,
            variables: {
                nodeId,
                take,
                skip
            },
            fetchPolicy: 'network-only'
        }).catch(err => {
            app.$notify({
                group: "error",
                type: "error",
                title: `Error!`,
                text: `${err.message}`
            });
        });

        if (res?.data) {
            commit(actionTypes.SET_MOTION_EVENTS, res?.data.motionEvents);
        }
    },

    getBatteryReplacementEventsByNode: async ({ commit }, payload) => {
        const { nodeId, take, skip } = payload
        const res = await app.$apollo.query({
            query: gql`${batteryReplacementEventsQuery}`,
            variables: {
                nodeId,
                take,
                skip
            },
            fetchPolicy: 'network-only'
        }).catch(err => {
            app.$notify({
                group: "error",
                type: "error",
                title: `Error!`,
                text: `${err.message}`
            });
        });

        if (res?.data) {
            commit(actionTypes.SET_BATTERY_REPLACEMENT_EVENTS, res.data.batteryReplacementEvents);
        }
    },

    getPowerEventsByNode: async ({ commit }, payload) => {
        const { nodeId, take, skip } = payload
        const res = await app.$apollo.query({
            query: gql`${powerEventsQuery}`,
            variables: {
                nodeId,
                take,
                skip
            },
            fetchPolicy: 'network-only'
        }).catch(err => {
            app.$notify({
                group: "error",
                type: "error",
                title: `Error!`,
                text: `${err.message}`
            });
        });

        if (res?.data) {
            commit(actionTypes.SET_POWER_EVENTS, res?.data.powerEvents);
        }
    },

    getSiteUpcomingTests: async ({ commit }, payload) => {
        const { siteId } = payload;

        const res = await app.$apollo.query({
            query: gql`${siteUpcomingTests}`,
            variables: {
                "id": siteId
            },
            fetchPolicy: 'network-only'
        }).catch(err => {
            app.$notify({
                group: "error",
                type: "error",
                title: `Error!`,
                text: `${err.message}`
            });
        });

        if (res?.data) {
            commit(actionTypes.SET_UPCOMING_TESTS, res.data.upcomingTests.tests);
        }
        
        commit(actionTypes.SITE_NODES, {
            rows: [],
            count: 0
        });

    },

    identifyNode: async ({ commit }, payload) => {
        let { nodeId, device_label } = payload;
        await app.$apollo.mutate({
            mutation: gql`${IDENTIFY}`,
            variables: {
                nodeId: nodeId
            }
        }).then(() => {
            if (device_label==null)
                device_label="device";
            app.$notify({
                group: "success",
                type: "success",
                title: "Success!",
                text: `Identifying ${device_label} ...`
            });
        });
    },

    getServerBLEVersion: async ({ commit, state }) => {
        if (state.serverBLEVersion !== -1) return;

        let version = -1;
        await app.$apollo.query({
            query: gql`${BLE_VERSION}`,
            fetchPolicy: 'network-only'
        }).then((resp) => {
            version = parseInt(resp.data.bleversion.version)
        });

        commit(actionTypes.SET_SERVER_BLE_VERSION, version);
    },

    deleteDeviceHistory: async ({ state }, payload) => {
        let { device_id } = payload;
        await app.$apollo.mutate({
            mutation: DELETE_DEVICE_HISTORY,
            variables: {
                "nodeId": device_id
            },
            update: (store, { data }) => {
                app.$notify({
                    group: "success",
                    type: "success",
                    title: "Success!",
                    text: `The device history was removed successfully!`
                });
                return true;
            }
        }).catch(err => {
            app.$notify({
                group: "error",
                type: "error",
                title: `Error!`,
                text: `${err.message}`
            });
            return [];
        });
    },

    clearNodeTestStatus: async ({ state }, payload) => {
        let { nodeId, device_label } = payload;
        await app.$apollo.mutate({
            mutation: gql`${RESET_TEST_STATUS}`,
            variables: {
                nodeId: nodeId
            }
        }).then(() => {
            app.$notify({
                group: "success",
                type: "success",
                title: "Success!",
                text: `Request Test Reset for ${device_label} ...`
            });
        });
    },

    siteTests: async ({ state }, payload) => {
        let { siteId, testType } = payload
        testType = TEST_TYPE_MAP[testType].toLowerCase()
        await app.$apollo.mutate({
            mutation: gql`${SITE_TEST}`,
            variables: {
                siteId: siteId,
                testType: testType,
            }
        }).then(() => {
            app.$notify({
                group: "success",
                type: "success",
                title: "Success!",
                text: `Starting Site ${testType} test...`
            });
        });
    },

    setSiteDiagnostics: async ({ state, commit }, payload) => {
        let { siteId, enabled } = payload
        await app.$apollo.mutate({
            mutation: gql`${SITE_DIAGNOSTICS}`,
            variables: {
                siteId: siteId,
                action: enabled,
            }
        }).then((msg) => {
            if(msg?.data?.siteDiagnostics?.detail) {
                app.$notify({
                    group: "error",
                    type: "error",
                    title: "Failed to enable site diagnostics",
                    text: msg?.data?.siteDiagnostics?.detail
                });
            } else {
                app.$notify({
                group: "success",
                type: "success",
                title: "Success!",
                text: enabled ? 'Enabling site diagnostics...' : 'Disabling site diagnostics...'
            });
            }
        });
    },

    updateFirmwareSensorSettingByNode: async ({ commit }, payload) => {
        let { nodeId } = payload;
        delete payload.nodeId
        await app.$apollo.mutate({
            mutation: gql`${UPDATE_FIRMWARE_SENSOR_SETTING_SET}`,
            variables: {
                nodeId: nodeId,
                data: payload
            }
        }).then((msg) => {
            if(msg?.data?.updateFirmwareSensorSetting?.Error) {
                app.$notify({
                    group: "error",
                    type: "error",
                    title: "Failed to set node sensor settings",
                    text: msg?.data?.updateFirmwareSensorSetting?.Error
                });
            } else {
                app.$notify({
                    group: "success",
                    type: "success",
                    title: "Success!",
                    text: `Settings sensor settings...`
                });
            }
        });
    },

    resetFirmwareSchedule: async ({ commit, state }, payload) => {
        let { nodeId, scheduleFreq, scheduleHour, scheduleMin } = payload;
        await app.$apollo.mutate({
            mutation: gql`${RESET_FIRMWARE_SCHEDULE}`,
            variables: {
                nodeId: nodeId,
                scheduleFreq: scheduleFreq,
                scheduleHour: scheduleHour,
                scheduleMin: scheduleMin,
            }
        }).then((msg) => {
            if(msg?.data?.nodeResetFirmwareSchedule?.Error) {
                app.$notify({
                    group: "error",
                    type: "error",
                    title: "Failed to reset schedule test",
                    text: msg?.data?.nodeResetFirmwareSchedule?.Error
                });
            } else {
                app.$notify({
                    group: "success",
                    type: "success",
                    title: "Success!",
                    text: `Resetting firmware schedule...`
                });
            }
        });
    },

    setFirmwareSchedule: async ({ state, commit }, payload) => {
        let { nodeId, enable } = payload;
        await app.$apollo.mutate({
            mutation: gql`${SET_FIRMWARE_SCHEDULE}`,
            variables: {
                nodeId: nodeId,
                enable: enable,
            }
        }).then((msg) => {
            if(msg?.data?.nodeSetFirmwareSchedule?.Error) {
                app.$notify({
                    group: "error",
                    type: "error",
                    title: "Failed to toggle node schedule test",
                    text: msg?.data?.nodeSetFirmwareSchedule?.Error
                });
                throw error;
            } else {
                app.$notify({
                group: "success",
                type: "success",
                title: "Success!",
                text: enable ? 'Enabling node schedule test...' : 'Disabling node schedule test...'
            });
            }
        });
    },

    getSiteDetails: async ({ commit }, payload) => {
        const { siteId } = payload;
        const res = await app.$apollo.query({
            query: gql`${siteDetailsQuery}`,
            fetchPolicy: 'network-only',
            variables: {
                "id": siteId
            },

        }).catch(err => {
            app.$notify({
                group: "error",
                type: "error",
                title: `Error!`,
                text: `${err.message}`
            });
            return;
        });
        if (res?.data?.site) {
            commit(actionTypes.SET_SITE, res?.data?.site);
        }
        return res?.data?.site || null;
    },
    createSite: async({ commit }, payload) => {
        return await app.$apollo.mutate({
            mutation: CREATE_SITE,
            variables: {
                data: payload.data,
            },
            update: (store, { data }) => {
                if (data.createSite.id) {
                    app.$notify({
                        group: "success",
                        type: "success",
                        title: "Success!",
                        text: "Site was created successfully!"
                    });
                    //app.$router.push(`/site/${data.createSite.id}`);
                }
            }
        }).catch((e) => {
            app.$notify({
                group: "error",
                type: "error",
                title: "Error!",
                text: e.message,
            });
        });
    },
    updateSiteDetails: async ({ commit }, payload) => {
        await app.$apollo.mutate({
            mutation: UPDATE_SITE_DETAILS,
            variables: {
                id: payload.id,
                data: payload.data,
            },
            update: (store, { data }) => {
                if (data.updateSite.id && !payload.hasOwnProperty('noNotify')) {
                    app.$notify({
                        group: "success",
                        type: "success",
                        title: "Success!",
                        text: "Site details was updated successfully!"
                    });
                }
            }
        }).catch((e) => {
            app.$notify({
                group: "error",
                type: "error",
                title: "Error!",
                text: e.message,
            });
        });
    },
    deleteSite: async ({ state }, payload) => {
        await app.$apollo.mutate({
            mutation: DELETE_SITE,
            variables: {
                id: payload.id,
            },
            update: (store, { data }) => {
                app.$notify({
                    group: "success",
                    type: "success",
                    title: "Success!",
                    text: `The site was removed successfully!`
                });
            }
        }).catch(err => {
            app.$notify({
                group: "error",
                type: "error",
                title: `Error!`,
                text: `${err.message}`
            });
        });
    },

    updateGateway: async ({ commit }, payload) => {
        await app.$apollo.mutate({
            mutation: UPDATE_GATEWAY,
            variables: {
                id: payload.id,
                data: payload.data,
            },
            update: (store, { data }) => {
                if (data.updateGateway.id && !payload.hasOwnProperty('noNotify')) {
                    app.$notify({
                        group: "success",
                        type: "success",
                        title: "Success!",
                        text: "Gateway was updated successfully!"
                    });
                }
            }
        }).catch((e) => {
            app.$notify({
                group: "error",
                type: "error",
                title: "Error!",
                text: e.message,
            });
        });
    },
    geocodingQuery: async ({ commit }, payload) => {
        let { address } = payload;
        const geocoderQuery = encodeURIComponent(address.replace(/ /g, '+'));
        const googleApiKey = process.env.VUE_APP_GOOGLE_API_KEY || 'AIzaSyBLy63-knk52y_C7QJ7f2-xnZ6KnJBOHY0';
        if (geocoderQuery?.length < 5) {
            return null;
        }
        try {
            return axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${geocoderQuery}&key=${googleApiKey}`)
                .then(res => {
                    return res.data;
                })
                .then(json => {
                    if (json.results.length === 0) {
                        return null
                    }
                    let lat = json.results['0'].geometry.location.lat
                    let lng = json.results['0'].geometry.location.lng
                    return { lat, lng }
                });
        } catch (err) {
            return { lat: null, lng: null }
        }
    },

    clearNodeSpiMemory: async ({ state }, payload) => {
        let { nodeId } = payload;
        await app.$apollo.mutate({
            mutation: gql`${SPI_MEMORY_DELETE_ALL}`,
            variables: {
                nodeId: nodeId,
            }
        }).then(() => {
            app.$notify({
                group: "success",
                type: "success",
                title: "Success!",
                text: `Clearing device SPI memory...`
            });
        })
    },

    getTenant: async ({commit}, payload) => {
        localStorage.removeItem('tenant');
        const { tenantId } = payload;
        const res = await app.$apollo.query({
            query: gql`${tenant}`,
            fetchPolicy: 'network-only',
            variables: {
                "id": tenantId
            }
        }).catch(err => {
            app.$notify({
                group: "error",
                type: "error",
                title: `Error!`,
                text: `${err.message}`
            });
            return;
        });
        if (res?.data?.tenant) {
          commit(actionTypes.SET_TENANT, res.data.tenant);
        }
        return res?.data?.tenant || null;
    },
    getTenants: async ({commit}, payload) => {
        localStorage.removeItem('tenants');
        const { take, skip, orderBy } = payload;
        const res = await app.$apollo.query({
            query: gql`${tenants}`,
            fetchPolicy: 'network-only',
            variables: {
                take,
                skip,
                orderBy,
            }
        }).catch(err => {
            app.$notify({
                group: "error",
                type: "error",
                title: `Error!`,
                text: `${err.message}`
            });
            return;
        });

        if (res?.data) {
          commit(actionTypes.SET_TENANTS, res.data.tenants);
        }
    },
    getTenantsName: async ({commit}, payload) => {
        localStorage.removeItem('tenantsName');
        const res = await app.$apollo.query({
            query: gql`${tenantsName}`,
            fetchPolicy: 'network-only',
        }).catch(err => {
            app.$notify({
                group: "error",
                type: "error",
                title: `Error!`,
                text: `${err.message}`
            });
            return;
        });

        if (res?.data) {
          commit(actionTypes.SET_TENANTSNAME, res.data.tenants);
        }
    },
    createTenant: async ({ commit }, payload) => {
        await app.$apollo.mutate({
            mutation: CREATE_TENANT,
            variables: {
                data: payload.data,
            },
            update: (store, { data }) => {
                if (data.createTenant.id) {
                    app.$notify({
                        group: "success",
                        type: "success",
                        title: "Success!",
                        text: "Tenant details were updated successfully!"
                    });
                    app.$router.push(`/tenantsmanage/${data.createTenant.id}`);
                }
            }
        }).catch((e) => {
            app.$notify({
                group: "error",
                type: "error",
                title: "Error!",
                text: e.message,
            });
        });
    },
    updateTenant: async ({ commit }, payload) => {
        await app.$apollo.mutate({
            mutation: UPDATE_TENANT,
            variables: {
                id: payload.id,
                data: payload.data,
            },
            update: (store, { data }) => {
                if (data.updateTenant.id && !payload.hasOwnProperty('noNotify')) {
                    app.$notify({
                        group: "success",
                        type: "success",
                        title: "Success!",
                        text: "Tenant details were updated successfully!"
                    });
                }
            }
        }).catch((e) => {
            app.$notify({
                group: "error",
                type: "error",
                title: "Error!",
                text: e.message,
            });
        });
    },
    deleteTenant: async ({ state }, payload) => {
        await app.$apollo.mutate({
            mutation: DELETE_TENANT,
            variables: {
                id: payload.id,
            },
            update: (store, { data }) => {
                app.$notify({
                    group: "success",
                    type: "success",
                    title: "Success!",
                    text: `The tenant was removed successfully!`
                });
            }
        }).catch(err => {
            app.$notify({
                group: "error",
                type: "error",
                title: `Error!`,
                text: `${err.message}`
            });
        });
    },
    
    getDevicegroup: async ({commit}, payload) => {
        localStorage.removeItem('devicegroup');
        const { devicegroupId } = payload;
        const res = await app.$apollo.query({
            query: gql`${devicegroup}`,
            fetchPolicy: 'network-only',
            variables: {
                "id": devicegroupId
            }
        }).catch(err => {
            app.$notify({
                group: "error",
                type: "error",
                title: `Error!`,
                text: `${err.message}`
            });
            return;
        });
        if (res?.data?.devicegroup) {
          commit(actionTypes.SET_DEVICEGROUP, res.data.devicegroup);
        }
        return res?.data?.devicegroup || null;
    },
    getDevicegroups: async ({commit}, payload) => {
        localStorage.removeItem('devicegroups');
        const { take, skip, orderBy } = payload;
        const res = await app.$apollo.query({
            query: gql`${devicegroups}`,
            fetchPolicy: 'network-only',
            variables: {
                take,
                skip,
                orderBy,
            }
        }).catch(err => {
            app.$notify({
                group: "error",
                type: "error",
                title: `Error!`,
                text: `${err.message}`
            });
            return;
        });

        if (res?.data) {
          commit(actionTypes.SET_DEVICEGROUPS, res.data.devicegroups);
        }
    },
    getDevicegroupsName: async ({commit}, payload) => {
        localStorage.removeItem('devicegroupsName');
        const { siteId } = payload;
        const res = await app.$apollo.query({
            query: gql`${devicegroupsName}`,
            fetchPolicy: 'network-only',
            variables: {
                siteId
            }
        }).catch(err => {
            app.$notify({
                group: "error",
                type: "error",
                title: `Error!`,
                text: `${err.message}`
            });
            return;
        });

        if (res?.data) {
          commit(actionTypes.SET_DEVICEGROUPSNAME, res.data.devicegroups);
        }
        return res?.data?.devicegroups?.rows || null;
    },
    createDevicegroup: async ({ commit }, payload) => {
        await app.$apollo.mutate({
            mutation: CREATE_DEVICEGROUP,
            variables: {
                data: payload.data,
            },
            update: (store, { data }) => {
                if (data.createDeviceGroup.id) {
                    app.$notify({
                        group: "success",
                        type: "success",
                        title: "Success!",
                        text: "Group details were updated successfully!"
                    });
                    app.$router.push(`/devicegroupsmanage/${data.createDeviceGroup.id}`);
                }
            }
        }).catch((e) => {
            app.$notify({
                group: "error",
                type: "error",
                title: "Error!",
                text: e.message,
            });
        });
    },
    updateDevicegroup: async ({ commit }, payload) => {
        await app.$apollo.mutate({
            mutation: UPDATE_DEVICEGROUP,
            variables: {
                id: payload.id,
                data: payload.data,
            },
            update: (store, { data }) => {
                if (data.updateDeviceGroup.id && !payload.hasOwnProperty('noNotify')) {
                    app.$notify({
                        group: "success",
                        type: "success",
                        title: "Success!",
                        text: "Group details were updated successfully!"
                    });
                }
            }
        }).catch((e) => {
            app.$notify({
                group: "error",
                type: "error",
                title: "Error!",
                text: e.message,
            });
        });
    },
    deleteDevicegroup: async ({ state }, payload) => {
        await app.$apollo.mutate({
            mutation: DELETE_DEVICEGROUP,
            variables: {
                id: payload.id,
            },
            update: (store, { data }) => {
                app.$notify({
                    group: "success",
                    type: "success",
                    title: "Success!",
                    text: `The group was removed successfully!`
                });
            }
        }).catch(err => {
            app.$notify({
                group: "error",
                type: "error",
                title: `Error!`,
                text: `${err.message}`
            });
        });
    },
    
    getSitesName: async ({commit}, payload) => {
        localStorage.removeItem('sitesName');
        const res = await app.$apollo.query({
            query: gql`${sitesName}`,
            fetchPolicy: 'network-only',
        }).catch(err => {
            app.$notify({
                group: "error",
                type: "error",
                title: `Error!`,
                text: `${err.message}`
            });
            return;
        });

        if (res?.data) {
          commit(actionTypes.SET_SITESNAME, res.data.sites);
        }
        return res?.data?.sites?.rows || null;
    },
    
    getEntity: async ({commit}, payload) => {
        localStorage.removeItem('entity');
        const { entityId } = payload;
        const res = await app.$apollo.query({
            query: gql`${entity}`,
            fetchPolicy: 'network-only',
            variables: {
                "id": entityId
            }
        }).catch(err => {
            app.$notify({
                group: "error",
                type: "error",
                title: `Error!`,
                text: `${err.message}`
            });
            return;
        });
        if (res?.data?.entity) {
          commit(actionTypes.SET_ENTITY, res.data.entity);
        }
        return res?.data?.entity || null;
    },
    getEntities: async ({commit}, payload) => {
        localStorage.removeItem('entities');
        const { take, skip, orderBy } = payload;
        const res = await app.$apollo.query({
            query: gql`${entities}`,
            fetchPolicy: 'network-only',
            variables: {
                take,
                skip,
                orderBy,
            }
        }).catch(err => {
            app.$notify({
                group: "error",
                type: "error",
                title: `Error!`,
                text: `${err.message}`
            });
            return;
        });
        if (res?.data) {
          commit(actionTypes.SET_ENTITIES, res.data.entities);
        }
    },
    createEntity: async ({ commit }, payload) => {
        return await app.$apollo.mutate({
            mutation: CREATE_ENTITY,
            variables: {
                data: payload.data,
            },
            update: (store, { data }) => {
                if (data.createEntity.id) {
                    app.$notify({
                        group: "success",
                        type: "success",
                        title: "Success!",
                        text: "Entity details were updated successfully!"
                    });
                    //app.$router.push(`/entitiessmanage/${data.createEntity.id}`);
                }
            }
        }).catch((e) => {
            app.$notify({
                group: "error",
                type: "error",
                title: "Error!",
                text: e.message,
            });
        });
    },
    updateEntity: async ({ commit }, payload) => {
        await app.$apollo.mutate({
            mutation: UPDATE_ENTITY,
            variables: {
                id: payload.id,
                data: payload.data,
            },
            update: (store, { data }) => {
                if (data.updateEntity.id) {
                    app.$notify({
                        group: "success",
                        type: "success",
                        title: "Success!",

            text: "Entity details were updated successfully!"
                    });
                }
            }
        }).catch((e) => {
            app.$notify({
                group: "error",
                type: "error",
                title: "Error!",
                text: e.message,
            });
        });
    },
    deleteEntity: async ({ state }, payload) => {
        await app.$apollo.mutate({
            mutation: DELETE_ENTITY,
            variables: {
                id: payload.id,
            },
            update: (store, { data }) => {
                app.$notify({
                    group: "success",
                    type: "success",
                    title: "Success!",
                    text: `The entity was removed successfully!`
                });
            }
        }).catch(err => {
            app.$notify({
                group: "error",
                type: "error",
                title: `Error!`,
                text: `${err.message}`
            });
        });
    },
    getEntitySites: async ({ commit }, payload) => {
        const {entityId} = payload || {entityId: null};

        // Get attached tenants
        let attached = await app.$apollo.query({
            query: gql`${entitySitesQuery}`,
            fetchPolicy: 'network-only',
            variables: {
                entityId
            }
        }).then(response => {
            return JSON.parse(JSON.stringify(response.data?.sites));
        }).catch(err => {
            app.$notify({
                group: "error",
                type: "error",
                title: `Error!`,
                text: `${err.message}`
            });
        });

        // Get detached gateways
        let detached = {count: 0, rows: []};
        if (entityId != null) {
            detached = await app.$apollo.query({
                query: gql`${entitySitesQuery}`,
                fetchPolicy: 'network-only',
                variables: {
                    entityId: null
                }
            }).then(response => {
                return JSON.parse(JSON.stringify(response.data?.sites));
            }).catch(err => {
                app.$notify({
                    group: "error",
                    type: "error",
                    title: `Error!`,
                    text: `${err.message}`
                });

            });
        }
        // Return entity sites
        return {attached, detached}
    },
    getEntityUsers: async ({ commit }, payload) => {
        const {entityId} = payload || {entityId: null};
        // Get attached users
        let attached = await app.$apollo.query({
            query: gql`${entityUsersQuery}`,
            fetchPolicy: 'network-only',
            variables: {
                entityId
            }
        }).then(response => {
            let data = response.data?.usersentities;
            let res = {count: data.count, rows: data.rows.map(item => {return item.user;})};
            return JSON.parse(JSON.stringify(res));
        }).catch(err => {
            app.$notify({
                group: "error",
                type: "error",
                title: `Error!`,
                text: `${err.message}`
            });
        });

        // Get detached users
        let detached = {count: 0, rows: []};
        if (entityId != null) {
            detached = await app.$apollo.query({
                query: gql`${entityAllUsersQuery}`,
                fetchPolicy: 'network-only'
            }).then(response => {
                let res = response.data?.users;
                let attachedIds = attached.rows.map(item => item.id);
                res.rows = response.data?.users.rows.filter(item => !attachedIds.includes(item.id));
                return JSON.parse(JSON.stringify(res));
            }).catch(err => {
                app.$notify({
                    group: "error",
                    type: "error",
                    title: `Error!`,
                    text: `${err.message}`
                });

            });
        }
        // Return entity users
        return {attached, detached}
    },
    createUserentity: async ({ commit }, payload) => {
        await app.$apollo.mutate({
            mutation: CREATE_USERENTITY,
            variables: {
              data: {
                userId: payload.userId,
                entityId: payload.entityId,
              },
            },
            update: (store, { data }) => {
                if (data.createUserentity.id && !payload.hasOwnProperty('noNotify')) {
                    app.$notify({
                        group: "success",
                        type: "success",
                        title: "Success!",
                        text: "User details were updated successfully!"
                    });
                }
            }
        }).catch((e) => {
            if (!payload.hasOwnProperty('noNotify')) {
              app.$notify({
                  group: "error",
                  type: "error",
                  title: "Error!",
                  text: e.message,
              });
            }
        });
    },
    deleteUserentity: async ({ commit }, payload) => {
        await app.$apollo.mutate({
            mutation: DELETE_USERENTITY,
            variables: {
                userId: payload.userId,
                entityId: payload.entityId,
            },
            update: (store, { data }) => {
                if (data.deleteUserentity.id && !payload.hasOwnProperty('noNotify')) {
                    app.$notify({
                        group: "success",
                        type: "success",
                        title: "Success!",
                        text: "User details were updated successfully!"
                    });
                }
            }
        }).catch((e) => {
            if (!payload.hasOwnProperty('noNotify')) {
              app.$notify({
                  group: "error",
                  type: "error",
                  title: "Error!",
                  text: e.message,
              });
            }
        });
    },
    
    setSiteNodesList_UserControl: ({ commit }, payload) => {
        commit(actionTypes.SET_SITENODESLIST_USERCONTROL, payload);
    },

    getSensorSettingByNode: async ({ commit }, payload) => {
        const { nodeId, take, skip } = payload
        const res = await app.$apollo.query({
            query: gql`${sensorSettingsQuery}`,
            variables: {
                nodeId
            },
            fetchPolicy: 'network-only'
        }).catch(err => {
            app.$notify({
                group: "error",
                type: "error",
                title: `Error!`,
                text: `${err.message}`
            });
        });

        return res?.data.sensorSettings.rows[0];
    },
    
    getGroupSensorSetting: async ({ commit }, payload) => {
        const { id } = payload
        const res = await app.$apollo.query({
            query: gql`${groupSensorSettingQuery}`,
            variables: {
                id
            },
            fetchPolicy: 'network-only'
        }).catch(err => {
            app.$notify({
                group: "error",
                type: "error",
                title: `Error!`,
                text: `${err.message}`
            });
        });
        return res?.data.groupSensorSetting;
    },
    
    updateGroupSensorSetting: async ({ commit }, payload) => {
        await app.$apollo.mutate({
            mutation: gql`${UPDATE_GROUP_SENSOR_SETTING}`,
            variables: {
                id: payload.id,
                data: payload.data
            }
        }).then((msg) => {
            if(msg?.data?.updateFirmwareSensorSetting?.Error) {
                app.$notify({
                    group: "error",
                    type: "error",
                    title: "Failed to set node sensor settings",
                    text: msg?.data?.updateFirmwareSensorSetting?.Error
                });
            } else {
                app.$notify({
                    group: "success",
                    type: "success",
                    title: "Success!",
                    text: `Settings sensor settings...`
                });
            }
        });
    },

    
    requestFirmwareSensorSettingByNode: async ({ commit }, payload) => {
        const { nodeId } = payload
        const res = await app.$apollo.query({
            query: gql`${requestFirmwareSensorSettingsQuery}`,
            variables: {
                nodeId
            },
            fetchPolicy: 'network-only'
        }).catch(err => {
            app.$notify({
                group: "error",
                type: "error",
                title: `Failed to request firmware sensor setting!`,
                text: `${err.message}`
            });
        });

        if (res?.data?.nodeSensorSettingGet?.Error)
            throw new Error("Failed to get node sensor setting");
        else
            return res?.data.nodeSensorSettingGet;
    },

}